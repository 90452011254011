<template>
  <div>
    <v-toolbar>
      <v-row no-gutters>
        <v-col></v-col>
        <v-col class="d-flex justify-end">
            <v-btn-toggle>
          <v-btn @click="fetchClients(30)" color="primary">30 dagen</v-btn>
          <v-btn @click="fetchClients(60)" color="primary">60 dagen</v-btn>
          <v-btn @click="fetchClients(90)" color="primary">90 dagen</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
      :headers="clientHeaders"
      :items="clients"
      :server-items-length="totalClients"
      :options.sync="options"
      @update:page="updateOptions"
      @update:items-per-page="updateOptions"
      item-key="_id"
      :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }"
    >
      <template v-slot:[`item.lastOrderDate`]="{ item }">
        {{ toDateString(item.lastOrderDate) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { errorHandler, fetchGET, toDateString } from "../js/functions"
export default {
  name: "lastOrderDate",
  data() {
    return {
      clients: [],
      totalClients: 1,
      clientHeaders: [
        { text: "Klantnr", value: "clientNumber" },
        { text: "Naam", value: "name" },
        { text: "Telefoon", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Adres", value: "address" },
        { text: "Postcode", value: "postalcode" },
        { text: "Stad", value: "city" },
        { text: "Land", value: "country" },
        { text: "Laatste order", value: "lastOrderDate" }
      ],
      options: {
        itemsPerPage: 50,
        page: 1
      }
    }
  },
  methods: {
    toDateString(n) {
      return toDateString(n)
    },
    async fetchClients(days) {
      try {
        this.clients = await fetchGET("lastOrderDate", {days:days})
      } catch (e) {
        errorHandler(e, "Kan gegevens niet ophalen")
      }
    },
    updateOptions() {}
  },
  mounted() {
    this.fetchClients(30)
  }
}
</script>

<style scoped>
</style>